import React, {useEffect} from 'react';
import {CardContainer} from 'sections/OurProjects/CardContainer';
import {PageWrapper, OverflowWrapper} from 'shared/Styles/Wrappers';
import {SortingTopBar} from 'sections/OurProjects/SortingTopBar';
import {BrushedTextContainer} from 'components/BrushedTextContainer';
import Image from 'gatsby-image';
import {useDispatch} from 'react-redux';
import {changeFilter} from '../store/projects/actions';
import {useData} from '../data/en/our-projects';
import {useEnBackgroundImage} from '../shared/Hooks/Background/useEnBackgroundImage';

const Projects = ({
  pageContext: {
    key,
    sortBy,
    slug,
  },
}) => {
  const dispatch = useDispatch();
  const data = useData();

  useEffect(() => {
    return () => {
      dispatch(changeFilter(''));
    };
  }, [dispatch]);

  const backgroundImgs = useEnBackgroundImage();

  return (
    <OverflowWrapper>
      <PageWrapper>
        <SortingTopBar slug={slug} data={data.projects.filters}/>
        <BrushedTextContainer x={[
          -10,
          15,
        ]} posY="-150px">
          <Image fixed={backgroundImgs.projects.childImageSharp.fixed}/>
        </BrushedTextContainer>
        <CardContainer data={data.projects.cards.filter(item => item[sortBy] === key)} cardBtnText={data.projects.cardBtnText}/>
      </PageWrapper>
    </OverflowWrapper>
  );
};

export default Projects;
